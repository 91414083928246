<!--
 * @Author: chenxing
 * @Date: 2021-11-11 18:24:28
 * @LastEditors: chenxing
 * @LastEditTime: 2022-01-18 17:55:54
-->
<template>
  <div style="height: 100vh; width: 100%; display: flex; align-items: center; justify-content: center">
    <video :src="videoUrl" controls></video>
  </div>
</template>

<script>
import { getUrl } from '@/utils/oss.js';
import { getOpUrl } from '@/utils/op_oss.js';
// import crypto from 'crypto-js';

export default {
  data() {
    return {
      videoUrl: '',
    };
  },
  created() {
    //1.获取当前路径的参数
    let urlString = this.$route.query.path;
    // let urlString = 'JTJGdmlkZW9zJTJGMjAyMSUyRjEyJTJGMzAlMkYwMS0xNjQwODU0MDI4NTU3Lm1wNA==';
    if (!urlString) {
      this.$router.push('/exception/404');
      return;
    }
    // let en = crypto.AES.encrypt('chenxing/2012', 'a9nmhg3ngh3v72szml502jks9');
    // let url = crypto.AES.decrypt(target, 'a9nmhg3ngh3v72szml502jks9').toString(crypto.enc.Utf8);
    let url = window.decodeURIComponent(window.atob(urlString));

    // 2.获取播放链接
    if (url.indexOf('op=1') > -1) {
      getOpUrl(url).then(res => {
        this.videoUrl = res;
      });
    } else {
      getUrl(url).then(res => {
        this.videoUrl = res;
      });
    }
  },
};
</script>

<style>
</style>